import useStyles from "./styles"
import ArrowForward from "./assets/arrow-forward.svg"
import { useLanguage } from "../../../lib/hooks/languageHooks"
import { strings as Localization } from "../../../lib/Localization"
import { signOut } from "../../../redux/userSlice"
import theme from "../../../theme"
import { useIsUserAuthorized } from "../../../redux/hook/userHooks"
import { desktopBreakpoint } from "../../../lib/helper/viewportHelper"
import {
    Box, Button, ListItem, ListItemIcon, ListItemText
} from "@mui/material"
import { withStyles } from "@mui/styles"
import { Favorite as FavoriteIcon } from "@mui/icons-material"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import AttachmentIcon from "@mui/icons-material/Attachment"
import GetAppIcon from "@mui/icons-material/GetApp"
import ReceiptIcon from "@mui/icons-material/Receipt"
import React from "react"
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import NoteIcon from "@mui/icons-material/Note"

const scrollWithOffset = el => {
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY
    const yOffset = -100
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" })
}

const LinkListItem = ({ listItem, mobile }) => (
    <>
        <ListItemText primary={listItem.text} />
        {
            !mobile && (
                <ListItemIcon style={{ justifyContent: "end" }}>
                    {listItem.icon}
                </ListItemIcon>
            )
        }

    </>
)

const StyledListItem = withStyles(t => ({
    root: {
        "&:focus": {
            backgroundColor: t.palette.common.white,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
                color: t.palette.common.black
            }
        },
        "& .MuiTypography-root": {
            [theme.breakpoints.up(desktopBreakpoint)]: {
                display: "inline-block",
                position: "relative",
                "&:after": {
                    content: "''",
                    position: "absolute",
                    width: "100%",
                    transform: "scaleX(0)",
                    height: "2px",
                    bottom: 0,
                    left: 0,
                    backgroundColor: "#F09057",
                    transformOrigin: "bottom right",
                    transition: "transform 0.25s ease-out"
                },
                "&:hover:after": {
                    transform: "scaleX(1)",
                    transformOrigin: "bottom left"
                }
            }
        },
        "&.MuiListItem-root": {
            width: "auto"
        }
    }
}))(ListItem)

const ListOptions = ({
    button, mobile = false, burgerMenu = false, userPage = false, handleOpenDrawer
}) => {
    const lang = useLanguage()
    const dispatch = useDispatch()
    const isAuthorized = useIsUserAuthorized()
    const language = useLanguage()
    const classes = useStyles()

    const myAccountLinkList = [
        {
            linkType: "link", path: `/${lang}/downloads`, target: "", text: Localization.fileDownload, icon: <GetAppIcon fontSize="small" />
        },
        {
            linkType: "link", path: `/${lang}/order-upload`, target: "", text: Localization.orderByFile, icon: <AttachmentIcon fontSize="small" />
        },
        {
            linkType: "link", path: `/${lang}/orders`, target: "", text: Localization.yourOrders, icon: <ReceiptIcon fontSize="small" />
        },
        {
            linkType: "link", path: `/${lang}/favorites`, target: "", text: Localization.yourFavorites, icon: <FavoriteIcon fontSize="small" />
        },
        {
            linkType: "link", path: `/${lang}/user`, target: "", text: Localization.yourAccount, icon: <AccountCircleIcon fontSize="small" />
        }
    ]
    const notLoggedInLinkList = [
        {
            linkType: "link", path: `/${lang}/proclip-and-holders`, target: "", text: Localization.proclipAndHolders
        },
        {
            linkType: "link", path: "/", target: "", text: Localization.allProducts
        },
        {
            linkType: "link", path: `/${lang}/#news`, target: "", text: Localization.news
        },
        {
            linkType: "link", path: `/${lang}/static/findRetailers`, target: "", text: Localization.findReseller
        },
        {
            linkType: "link", path: `/${lang}/contact`, target: "", text: Localization.contactBrodit
        }
    ]
    const loggedInLinkList = [
        {
            linkType: "link", path: `/${lang}/proclip-and-holders`, target: "", text: Localization.proclipAndHolders
        },
        {
            linkType: "link", path: "/", target: "", text: Localization.allProducts
        },
        {
            linkType: "link", path: `/${lang}/#news`, target: "", text: Localization.news
        },
        {
            linkType: "link", path: `/${lang}/contact`, target: "", text: Localization.contactBrodit
        }
    ]

    const returnSlip = {
        text: Localization.returnNote,
        icon: <NoteIcon fontSize="small" />
    }

    return (
        <Box style={{
            display: "flex",
            flexDirection: "column",
            alignItems: !userPage ? "flex-end" : "",
            background: !userPage ? "#FFF" : "",
            boxShadow: !userPage ? "" : "",
            paddingTop: "0!important"
        }}
        >
            {
                (!isAuthorized && mobile) && (
                    <>
                        {
                            notLoggedInLinkList.map(el => {
                                const shouldLink = el.path !== "/"

                                return (
                                    <StyledListItem
                                        className={classes.listItem}
                                        key={el.text}
                                        component={!shouldLink ? Button : HashLink}
                                        onClick={!shouldLink ? () => handleOpenDrawer() : null}
                                        to={el.path}
                                        scroll={el1 => scrollWithOffset(el1)}
                                        dense
                                    >
                                        <LinkListItem className={classes.hoverUnderline} listItem={el} mobile={mobile} />
                                        {!shouldLink && <img src={ArrowForward} alt="Forward arrow" />}
                                    </StyledListItem>
                                )
                            })
                        }
                    </>
                )
            }
            {
                (isAuthorized && mobile) && (
                    <>
                        {
                            loggedInLinkList.map(el => (
                                <StyledListItem
                                    style={{
                                        width: "100%",
                                        textDecoration: "none",
                                        color: theme.palette.common.black,
                                        textAlign: "end"
                                    }}
                                    key={el.text}
                                    component={el.path === "/" ? Button : HashLink}
                                    onClick={el.path === "/" ? () => handleOpenDrawer() : null}
                                    to={el.path}
                                    scroll={el2 => scrollWithOffset(el2)}
                                    dense
                                >
                                    <LinkListItem className={classes.hoverUnderline} listItem={el} mobile={mobile} />
                                </StyledListItem>
                            ))
                        }
                    </>
                )
            }
            {
                (!burgerMenu && !mobile) && (
                    <>
                        {myAccountLinkList.map(el => (
                            <StyledListItem
                                style={{
                                    textDecoration: "none",
                                    color: theme.palette.common.black
                                }}
                                key={el.text}
                                component={el.path === "/" ? Button : HashLink}
                                onClick={el.path === "/" ? handleOpenDrawer() : null}
                                to={el.path}
                                scroll={el3 => scrollWithOffset(el3)}
                                dense
                            >
                                <LinkListItem className={classes.hoverUnderline} listItem={el} />
                            </StyledListItem>
                        ))}
                    </>
                )
            }
            {
                (lang === "sv" && isAuthorized) && (
                    <StyledListItem
                        component={Link}
                        to={`${process.env.REACT_APP_IMAGE_URL}MAN-193.pdf`}
                        dense
                        target="_blank"
                        style={{
                            width: (!mobile && !button) ? "50%" : "100%",
                            textDecoration: "none",
                            color: theme.palette.common.black,
                            textAlign: "end"
                        }}
                    >
                        <LinkListItem className={classes.hoverUnderline} listItem={returnSlip} />
                    </StyledListItem>
                )
            }
            <Box
                style={{
                    marginTop: "24px", marginBottom: "6px", paddingLeft: "16px", paddingRight: "16px"
                }}
            >
                {isAuthorized && (
                    <Button
                        id="signOut"
                        style={{
                            display: "flex",
                            padding: "10px 20px",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            borderRadius: "4px",
                            backgroundColor: "#F09057",
                            color: "#2D2D2D",
                            /* M3/label/medium */
                            fontFamily: "Arial",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "16px", /* 114.286% */
                            letterSpacing: "0.5px"
                        }}
                        to="/"
                        onClick={() => dispatch(signOut())}
                        component={Link}
                        aria-label="Log out"
                    >
                        {Localization.logOut}
                    </Button>
                )}
                {!isAuthorized && (
                    <Button
                        id="login"
                        style={{
                            display: "flex",
                            padding: "10px 20px",
                            marginBottom: "8px!important",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                            borderRadius: "4px",
                            backgroundColor: "#F09057",
                            color: "#2D2D2D",
                            /* M3/label/medium */
                            fontFamily: "Arial",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "16px", /* 114.286% */
                            letterSpacing: "0.5px"
                        }}
                        to={`/${language}/signin`}
                        component={Link}
                        aria-label="Log in"
                    >
                        {Localization.logIn}
                    </Button>
                )}
            </Box>
        </Box>
    )
}

export default ListOptions
