import useStyles from "./styles"
import Logotype from "./assets/logotype.svg"
import Contact from "./components/Contact/Contact"
import { useLanguage } from "../../lib/hooks/languageHooks"
import React from "react"
import { Link } from "react-router-dom"

const Footer = () => {
    const language = useLanguage()
    const classes = useStyles()
    const [data, setData] = React.useState(null)

    const fetchStaticData = async () => {
        const response = await fetch(`/files/DynamicPages/Footer-${language}.json`)
        return response.json()
    }

    React.useEffect(() => {
        fetchStaticData().then(result => setData(result))
    }, [false, language])

    const cleanPhoneNumber = phone => phone.replace(/\(0\)|\s+|-/g, "")

    const handleOnClick = () => {
        window.scrollTo(0, 0)
    }

    const renderLink = link => {
        const url = `/${language}/${link.url}`

        switch (link.type) {
            case "anchor":
                return <Link className={classes.anchor} to={url} onClick={() => handleOnClick(url)} key={link.text}>{link.text}</Link>
            case "button":
                return <Link className={classes.anchorButtonStyle} to={url} onClick={() => handleOnClick(url)} key={link.text}>{link.text}</Link>
            case "external":
                return <Link className={classes.anchor} to={link.url} key={link.text} target="_blank">{link.text}</Link>
            default:
                return <Link to={url} onClick={() => handleOnClick(url)} key={link.text}>{link.text}</Link>
        }
    }

    const linkGroup = group => (
        <div key={`footer-item-${group.title}`} className={classes.gridItem} id={`footer-item-${group.title}`}>
            <span className={classes.groupTitle}>{group.title}</span>
            {group.links.map(link => renderLink(link))}
        </div>
    )

    return (
        <footer className={classes.footer}>
            <Contact />
            <div className={classes.footerGrid}>
                <div className={classes.logotypeWrapper}>
                    <img className={classes.logotype} src={Logotype} alt="Brodit logotype" />
                </div>
                {data
                    && (
                        <div className={classes.gridItem}>
                            <div className={classes.addressWrapper}>
                                <div>{data.companyInfo.name}</div>
                                <div>{data.companyInfo.address}</div>
                            </div>
                            <div className={classes.addressAnchorWrapper}>
                                <div className={classes.innerAnchorWrapper}>
                                    <a className={classes.addressAnchor} href={`tel: ${cleanPhoneNumber(data.companyInfo.phone)}`}>
                                        {data.companyInfo.phone}
                                    </a>
                                </div>
                                <div className={classes.innerAnchorWrapper}>
                                    <a className={classes.addressAnchor} href={`mailto: ${data.companyInfo.email}`}>
                                        {data.companyInfo.email}
                                    </a>
                                </div>
                            </div>
                        </div>
                    )}
                {data && data.linkGroups.map(item => linkGroup(item))}
            </div>
        </footer>
    )
}

export default Footer
