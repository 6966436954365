/* eslint-disable import/no-cycle */
import DesktopSearchArea from "./SearchArea/DesktopSearchArea"
import MobileSearchArea from "./SearchArea/MobileSearchArea"
import useStyles from "./styles"
import {
    AsyncSearch,
    Menu2Drawer
} from ".."
import largeLogo from "../../assets/largelogo.png"
import { strings as Localization } from "../../lib/Localization"
import { tabletBreakpoint } from "../../lib/helper/viewportHelper"
import { useLanguage } from "../../lib/hooks/languageHooks"
import { useIsShowingNavbarSearch } from "../../redux/hook/settingsHooks"
import { useUserAuthToken } from "../../redux/hook/userHooks"
import { setProxyPath, setRecaptchaSiteKey, setShowNavbarSearch } from "../../redux/settingsReducer"
import theme from "../../theme"
import useWindowDimensions from "../../redux/hook/windowHooks"
import { LeftRightSteeringFilter } from "../AutocompleteSearch"
import MultiSearchbar from "../AutocompleteSearch/MultiSearchbar/MultiSearchbar"
import { NavLink, Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import React, {
    useEffect, useRef, useState
} from "react"
import {
    AppBar, Box, Toolbar,
    Typography,
    useMediaQuery
} from "@mui/material"

const Navbar = () => {
    const language = useLanguage()
    const dispatch = useDispatch()
    const classes = useStyles()

    const [drawerIsOpen, setDrawerIsOpen] = useState(false)
    const navbarRef = useRef()
    const searchBoxRef = useRef()
    const isDesktop = useMediaQuery(theme.breakpoints.up(tabletBreakpoint))
    const showingSearch = useIsShowingNavbarSearch()
    const isAuth = useUserAuthToken()
    const windowSize = useWindowDimensions()
    const [hide, setHide] = useState(false)

    useEffect(() => {
        dispatch(setProxyPath(process.env.REACT_APP_PROXY_PATH))
        dispatch(setRecaptchaSiteKey(process.env.REACT_APP_RECAPTCHA_SITEKEY))
    }, [])

    const handleOpenDrawer = () => setDrawerIsOpen(prev => !prev)

    const handleBackDropClick = () => setDrawerIsOpen(false)

    const toggleShowSearch = (input = null) => {
        if (input === false) dispatch(setShowNavbarSearch(false))
        else dispatch(setShowNavbarSearch(!showingSearch))
    }

    const handleOnClick = () => {
        window.scrollTo(0, 0)
    }

    useEffect(() => { setDrawerIsOpen(false) }, [windowSize])

    const handleClickOutside = event => {
        if (event.target.classList.value.length === 0) return
        if (!event.target.classList.contains("MuiAutocomplete-option")
            && !event.target.classList.contains("MuiAutocomplete-listbox")
            && !event.target.classList.contains("MuiAutocomplete-dropdown")
            && !event.target.classList.contains("MuiAutocomplete-wrapper")
            && !event.target.classList.contains("MuiInputBase-input")
            && !event.target.classList.contains("MuiBox-root")
            && !event.target.classList.contains("MuiSvgIcon-root")
            && !event.target.classList.contains("MuiAutocomplete-input")
            && !event.target.classList.contains("makeStyles-dropdownButton-60")
            && !event.target.classList.contains("makeStyles-dropdownButton-64")
            && !event.target.classList.contains("makeStyles-truncatedSearchString-65")
            && !event.target.classList.contains("makeStyles-option-69")
            && !event.target.classList.contains("MuiAutocomplete-endAdornment")
        ) setHide(false)
        if (
            searchBoxRef?.current && !searchBoxRef?.current.contains(event.target)
            && navbarRef?.current && !navbarRef?.current.contains(event.target)
            && !event.target.id.includes("autocomplete-search")
        ) {
            dispatch(setShowNavbarSearch(false))
        }
    }

    useEffect(() => {
        if (showingSearch) {
            document.addEventListener("mousedown", handleClickOutside)
        } else {
            document.removeEventListener("mousedown", handleClickOutside)
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [showingSearch])

    const [openVehicleSearch, setOpenVehicleSearch] = useState(false)
    const [openDeviceSearch, setOpenDeviceSearch] = useState(false)

    const handleVehicleSearchClick = () => {
        setOpenVehicleSearch(true)
        setOpenDeviceSearch(false)
    }

    const handleDeviceSearchClick = () => {
        setOpenVehicleSearch(false)
        setOpenDeviceSearch(true)
    }

    const handleCloseVehicleSearch = () => {
        setOpenVehicleSearch(false)
    }

    const handleCloseDeviceSearch = () => {
        setOpenDeviceSearch(false)
    }

    return (
        <>
            {!hide
                && (
                    <AppBar ref={navbarRef} style={{ flexDirection: "row", display: hide ? "none" : "block" }} position="sticky" className={classes.appBar} color="inherit">
                        {isDesktop && (
                            <Box className={classes.topBox}>
                                <Box className={classes.topBoxTextWrapper}>
                                    {isAuth !== null && (
                                        <>
                                            <NavLink to={`/${language}/downloads`} style={{ textDecoration: "none" }} className={classes.hoverUnderline} end>
                                                <Typography className={classes.topBoxText} style={{ fontSize: "12px" }}>
                                                    {Localization.downloadCatalog}
                                                </Typography>
                                            </NavLink>
                                            <NavLink to={`/${language}/contact`} style={{ textDecoration: "none" }} className={classes.hoverUnderline} end>
                                                <Typography className={classes.topBoxText} style={{ fontSize: "12px" }}>
                                                    {Localization.contactBrodit}
                                                </Typography>
                                            </NavLink>
                                        </>
                                    )}
                                    {isAuth === null && (
                                        <>
                                            <NavLink to={`/${language}/static/becomeRetailer`} style={{ textDecoration: "none" }} className={classes.hoverUnderline} end>
                                                <Typography className={classes.topBoxText} style={{ fontSize: "12px" }}>
                                                    {Localization.becomeReseller.toUpperCase()}
                                                </Typography>
                                            </NavLink>
                                            <NavLink to={`/${language}/contact`} style={{ textDecoration: "none" }} className={classes.hoverUnderline} end>
                                                <Typography className={classes.topBoxText} style={{ fontSize: "12px" }}>
                                                    {Localization.contactBrodit.toUpperCase()}
                                                </Typography>
                                            </NavLink>
                                        </>
                                    )}
                                </Box>
                            </Box>
                        )}
                        <Toolbar className={classes.toolbar} disableGutters>
                            <div className={classes.navWrapper}>
                                <Link
                                    className={classes.logo}
                                    component={NavLink}
                                    to={`/${language}`}
                                    onClick={handleOnClick}
                                >
                                    <img
                                        src={largeLogo}
                                        alt="Brodit logo"
                                        className={classes.image}
                                    />
                                </Link>
                                <div>
                                    {isDesktop
                                        && (
                                            <DesktopSearchArea
                                                handleOpenDrawer={handleOpenDrawer}
                                                drawerIsOpen={drawerIsOpen}
                                            />
                                        )}
                                    {!isDesktop
                                        && (
                                            <MobileSearchArea
                                                toggleShowSearch={toggleShowSearch}
                                            />
                                        )}
                                </div>
                            </div>
                        </Toolbar>
                        <Menu2Drawer
                            open={drawerIsOpen}
                            onClose={handleBackDropClick}
                            toggleDrawer={handleOpenDrawer}
                        />
                    </AppBar>
                )}
            <Box
                ref={searchBoxRef}
                style={{
                    width: "100%",
                    display: showingSearch ? "flex" : "none",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    position: "sticky",
                    top: hide ? "0" : "104px",
                    left: 0,
                    zIndex: 99,
                    backgroundColor: "#FFF",
                    gap: "6px"
                }}
            >
                <MultiSearchbar
                    show={openDeviceSearch}
                    onClose={handleCloseDeviceSearch}
                    onClick={handleDeviceSearchClick}
                />
                <Box style={{
                    display: "flex", justifyContent: "flex-end", width: "95%"
                }}
                >
                    {
                        (language === "en")
                        && <LeftRightSteeringFilter style={{ marginRight: "5%" }} />
                    }
                </Box>
                <MultiSearchbar
                    vehicles
                    show={openVehicleSearch}
                    onClose={handleCloseVehicleSearch}
                    onClick={handleVehicleSearchClick}
                />
                <AsyncSearch />
            </Box>
        </>
    )
}

export default Navbar
